<template>
  <div class="option_card_text">
    <v-card height="152">
      <v-card-text class="pt-1"> 
        <v-checkbox color="primary" dense v-model="bodyparts" label="Bodyparts"></v-checkbox>
        <v-checkbox color="primary" dense v-model="keypoints" label="Keypoints"></v-checkbox>
        <v-checkbox color="primary" dense v-model="showbos" label="Showbos"></v-checkbox>
      </v-card-text>
    </v-card>
    
  </div>
</template>

<script>

export default {
  name: "OptionMenu",
  components: {
    
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      bodyparts: true,
      keypoints: true,
      showbos: true,

    };
  },
  methods: {

  },
  mounted() {

  },

  created() {

  },
  beforeDestroy() {

  },
  watch: {
    bodyparts() {
       window.parent.postMessage({
       'option': 'bodyparts',
        'value': this.bodyparts
     }, "*")
    },
    keypoints() {
      window.parent.postMessage({
       'option': 'keypoints',
        'value': this.keypoints
     }, "*")
    },
    showbos() {
      window.parent.postMessage({
       'option': 'showbos',
        'value': this.showbos
     }, "*")
    }
  }
};
</script>

<style scoped>

</style>